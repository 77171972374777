import React, { Fragment } from "react"
import { Styled, css } from "theme-ui"

const Footer = ({ socialLinks }) => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
      mr: 'auto',
      ml: 'auto',
      textAlign: 'center',
      textDecoration: `none`,
      fontSize: 1,
    })}
  >
    © {new Date().getFullYear()}
    {` `}
    <Styled.a href="https://www.pedrocosta.eu"
        css={css({
          textDecoration: 'none',
          ":hover": {
            textDecoration: `underline`,
          },
        })} 
    >Pedro Costa</Styled.a>
    {` `}&bull;{` `}
    {socialLinks.map((platform, i, arr) => (
      <Fragment key={platform.url}>
        <Styled.a href={platform.url} target="_blank" rel="noopener noreferrer" 
          css={css({
            textDecoration: 'none',
            ":hover": {
              textDecoration: `underline`,
            },
          })} 
        >
          {platform.name}
        </Styled.a>
        {arr.length - 1 !== i && (
          <Fragment>
            {` `}&bull;{` `}
          </Fragment>
        )}
      </Fragment>
    ))}
  </footer>
)
export default Footer