/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import { Link } from "gatsby"

const PostLink = ({ title, slug, date, excerpt }) => (
  <div sx={{
    mb: 4,
  }}>
    <Styled.h3
      sx={{
        mb: 1,
      }}
    >
      <Styled.a
        as={Link}
        sx={{
          textDecoration: `none`,
        }}
        to={slug}
      >
        {title || slug}
      </Styled.a>
    </Styled.h3>
    <small>{date}</small>
    <Styled.p>{excerpt}</Styled.p>
  </div>
)

export default PostLink